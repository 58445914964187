<template>
  <div class="content__scroll">
    <h1>
      Full Stack Web Developer
    </h1>
    <div class="bio">
      <p>
        I've been working as a developer professionally for over 10 years,
        based in Melbourne Australia.
      </p>
      <p>
        My passion for tech was sparked in `97 when my parents bought
        our first family computer running Windows 95. Fascinated by game 
        mechanics borderline obsessed, this lead me down the programming path. 
      </p>
      <p>
        I love to solve problems by building useful tools that enhance my everyday life and
        share them with other like-minded people.
      </p>
      <p>
        Check out some of my personal projects or get in touch if you'd like to chat.'
      </p>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.$emit('positionDot', {'top': 'auto', 'right': 'auto', 'bottom': '30px', 'left': '100px'})
  },
  beforeRouteLeave(to, from, next) {
      this.$emit('addAnimation', ['animate-dot__up'])
      setTimeout(() => {
        next()
      }, 500);
    },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from.name != null){
        vm.$emit('addAnimation', ['animate-dot__down'])
        setTimeout(() => {
          vm.$emit('removeAnimation', ['animate-dot__up','animate-dot__down'])
        }, 1000);
      }
    })
  }
}
</script>

<style scoped>
  .bio {
    margin-top: 50px;
    padding-bottom: 20px;
    width: 70%;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) { 
  }

  @media (min-width: 1200px) {
    .bio {
      width: 50%;
    }
  }
</style>